<template>
    <div class="flex flex-col gap-8 my-8 text-valence-grey-800 items-center">
        <p class="text-base font-medium">Whoops!</p>
        <p class="mx-auto max-w-lg text-center">Well, this is embarassing, but we can't seem to find that page. While we work on it, use this link to return to the home page.</p>
        <p>
            <a href="/" class="btn rounded-full bg-valence-pink hover:bg-valence-pink-600 transition-colors text-white font-semibold text-base px-[80px] py-3">
                Return to the home page
            </a>
        </p>
    </div>
</template>

<script setup>
import LeftSidebarLayout from "~vue/layouts/LeftSidebarLayout.vue";
import { logUserInteraction } from "~vue/utils/logUtils.js";
import { defineOptions, onMounted } from "vue";

defineOptions({
    layout: LeftSidebarLayout,
});

onMounted(() => {
    logUserInteraction("saw_not_found_page");
});
</script>
